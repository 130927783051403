export default function() {
  function closePopup() {
    const closeButton = document.querySelector(".js-close-popup");
    const signupBar = document.querySelector(".signup-bar");

    if (!closeButton || !signupBar) return;

    closeButton.addEventListener("click", function() {
      signupBar.classList.add("is-hidden");
    });
  }

  closePopup();
}