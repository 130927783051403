import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollToPlugin);

import mobileNav from './modules/mobile-nav';
import nav from './modules/nav';
import search from './modules/search';
import team from './modules/team';
import filters from './modules/filters';
import carousel from './modules/carousel';
import homeCarousel from './modules/home-carousel';
import map from './modules/map';
import featured from './modules/featured';
import section from './modules/section';
import note from './modules/image-note';
import header from './modules/header';
import closePopup from './modules/popup';

mobileNav.init();
nav.init();
filters.init();
section();
note();
header();
closePopup();

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);

if (history.scrollRestoration) {
  history.scrollRestoration = 'manual';
}

window.addEventListener('load', (e) => {
  search.init();
  team.init();
  carousel.init();
  homeCarousel();
  map.init();
  featured();

  if (window.location.hash) {
    const selector = window.location.hash;
    const matching = document.querySelector(selector);

    if (matching) {
      gsap.to(window, {
        duration: 0.5,
        scrollTo: {
          y: selector,
          x: 0
        }
      });
    }
  }
});

window.addEventListener('popstate', (e) => {
  if (window.location.hash) {
    const selector = window.location.hash;
    const matching = document.querySelector(selector);

    if (matching) {
      gsap.to(window, {
        duration: 0.5,
        scrollTo: {
          y: selector,
          x: 0
        }
      });
    }
  }
});
